import styled from 'styled-components';


const Button = styled.button`
  width: 100%;
  padding: 19px;
  background-color: #4162FD;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default Button;
