import React from "react";
import styled from "styled-components";
import Container from "../Components/Container";
import Row from "../Components/Row";
import LogoBlue from '../assets/images/logo-blue.svg'

const Title = styled.p`
  font-size: 40px;
  font-weight: 800;
  margin-bottom: 60px;
  text-align: center;
`;

const Date = styled.p`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 60px;
`;

const Subtitle = styled.h2`
  font-size: 18px;
  font-weight: 700;
  margin: 0px;
`;

const Section = styled.div`
  p {
    font-weight: 200;
    font-size: 16px;
  }
`;

const List = styled.ul`
  list-style-type: disc;
  margin-left: 0px;
`;

const ListItem = styled.li`
  margin-bottom: 5px;
`;

const BoldText = styled.span`
  font-weight: 700;
`;

const TermsOfUse = () => {
  return (
    <Container style={{ lineHeight: "35px" }}>
      <Title>Termos de Uso</Title>
      <Date>Última atualização: <span style={{fontWeight: 200}}>07/10/2024</span></Date>

      <Section>
        <Subtitle>1. Aceitação dos Termos</Subtitle>
        <p>
          Ao utilizar o aplicativo Room ("App"), você concorda com os presentes
          Termos de Uso. Se você não concordar com qualquer parte destes Termos,
          não deverá utilizar o App. O uso do App implica na aceitação integral
          das condições aqui estabelecidas.
        </p>
      </Section>

      <Section>
        <Subtitle>2. Descrição dos Serviços</Subtitle>
        <p>
          O Room é um aplicativo que oferece serviços de gestão de aluguéis,
          incluindo a criação de contratos de aluguel personalizados,
          assinaturas digitais com validade jurídica e gestão de cobranças de
          aluguel via PIX. A utilização do Room implica na aceitação do modelo
          de cobrança estabelecido, conforme detalhado abaixo.
        </p>
      </Section>

      <Section>
        <Subtitle>3. Criação e Assinatura de Contratos</Subtitle>
        <p>
          O Room permite que o usuário (proprietário) crie contratos de aluguel
          personalizados para seus imóveis. No entanto, o contrato assinado
          digitalmente só ficará disponível ao proprietário após o primeiro
          pagamento do inquilino ser processado via o App, momento em que será
          descontada a taxa de 5% referente ao uso do serviço. Até que isso
          ocorra, o contrato não estará liberado para ambas as partes.
        </p>
      </Section>

      <Section>
        <Subtitle>4. Cobrança e Pagamentos</Subtitle>
        <List>
          <ListItem>
            O Room cobra uma taxa de <BoldText>5%</BoldText> sobre cada
            pagamento de aluguel processado através do App, sendo{" "}
            <BoldText>2%</BoldText> correspondente à taxa de transação do PIX e{" "}
            <BoldText>3%</BoldText> destinada à manutenção e operação da
            plataforma.
          </ListItem>
          <ListItem>
            A taxa é aplicada apenas quando o inquilino efetua o pagamento e o
            proprietário recebe o valor, garantindo transparência e
            previsibilidade de custos. Caso o pagamento não ocorra, não há
            qualquer cobrança.
          </ListItem>
          <ListItem>
            O Room automatiza as cobranças, enviando notificações ao inquilino e
            garantindo que o proprietário seja notificado quando o pagamento for
            realizado.
          </ListItem>
        </List>
      </Section>

      <Section>
        <Subtitle>5. Garantias e Validade Jurídica</Subtitle>
        <p>
          Os contratos gerados e assinados através do Room possuem validade
          jurídica conforme a legislação brasileira vigente. No entanto, o Room
          não se responsabiliza pela revisão jurídica de cada contrato, sendo de
          responsabilidade do usuário garantir que os termos preenchidos estejam
          em conformidade com suas necessidades e obrigações legais.
        </p>
      </Section>

      <Section>
        <Subtitle>6. Obrigações do Usuário</Subtitle>
        <List>
          <ListItem>
            <BoldText>Cadastro:</BoldText> Para utilizar o App, o usuário deve
            fornecer informações verdadeiras e completas, incluindo dados
            pessoais e financeiros. O Room não se responsabiliza por qualquer
            dano ou prejuízo causado por informações incorretas ou falsas.
          </ListItem>
          <ListItem>
            <BoldText>Utilização:</BoldText> O usuário compromete-se a utilizar
            o App de maneira ética e conforme a legislação aplicável. Qualquer
            uso indevido pode resultar na suspensão ou cancelamento da conta.
          </ListItem>
        </List>
      </Section>

      <Section>
        <Subtitle>7. Política de Privacidade</Subtitle>
        <p>
          O Room se compromete a proteger a privacidade de seus usuários e a
          utilizar seus dados pessoais apenas para os fins descritos em nossa
          Política de Privacidade, disponível <a href="#">aqui</a> (link para a
          política de privacidade).
        </p>
      </Section>

      <Section>
        <Subtitle>8. Limitação de Responsabilidade</Subtitle>
        <List>
          <ListItem>
            O Room não se responsabiliza por pagamentos não realizados pelo
            inquilino;
          </ListItem>
          <ListItem>
            Problemas com a conexão de internet ou outros fatores externos que
            possam afetar o funcionamento do App;
          </ListItem>
          <ListItem>
            Qualquer perda, direta ou indireta, relacionada ao uso do App ou à
            sua incapacidade de utilizar o serviço.
          </ListItem>
        </List>
      </Section>

      <Section>
        <Subtitle>9. Alterações nos Termos de Uso</Subtitle>
        <p>
          O Room reserva-se o direito de modificar os Termos de Uso a qualquer
          momento, com aviso prévio aos usuários através do App ou por e-mail. O
          uso continuado do App após a alteração implica na aceitação dos novos
          termos.
        </p>
      </Section>

      <Section>
        <Subtitle>10. Cancelamento e Encerramento da Conta</Subtitle>
        <p>
          O usuário pode encerrar sua conta a qualquer momento, entrando em
          contato com nosso suporte. O Room também pode encerrar a conta do
          usuário em caso de violação dos presentes Termos.
        </p>
      </Section>

      <Section>
        <Subtitle>11. Foro e Legislação Aplicável</Subtitle>
        <p>
          Estes Termos são regidos pela legislação brasileira. Qualquer disputa
          relacionada ao uso do App será resolvida no foro da comarca de São
          Luís - MA, com exclusão de qualquer outro.
        </p>
      </Section>
      <Subtitle style={{fontSize: '16px'}}>contato@meuroom.com.br.</Subtitle>
      <Row
          xs="1fr 1fr"
          sm="1fr 1fr"
          md="1fr 1fr"
          lg="1fr 1fr"
          xl="1fr 1fr"
          style={{ marginTop: "100px", marginBottom: '30px', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <img src={LogoBlue} width={'53px'} />
          <span style={{fontWeight: 200, textAlign: 'right'}}>© 2024 Room</span>
        </Row>
    </Container>
  );
};

export default TermsOfUse;
