import React, { useState, useRef } from "react";
import styled from "styled-components";
import IconOpenClose from "../assets/images/icon-open-close.svg";

const Container = styled.div`
  width: 100%;
  margin: 20px auto;
`;

const CollapsibleHeader = styled.button`
  color: #1e1e1e;
  background-color: #ffffff;
  padding: 15px;
  width: 100%;
  border: none;
  cursor: pointer;
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  line-height: 30px;
  align-items: center;
`;

const CollapsibleContent = styled.div<{ isOpen: boolean }>`
  overflow: hidden;
  max-height: ${({ isOpen }) => (isOpen ? "500px" : "0")};
  transition: all 0.6s ease;
  padding: ${({ isOpen }) => (isOpen ? "15px" : "0 15px")};
  border-radius: 0 0 8px 8px;
  text-align: left;
  font-size: 16px;
  font-weight: regular;
`;

const CollapsibleIcon = styled.img<{ isOpen: boolean }>`
  transition: rotate 0.6s ease;
  rotate: ${({ isOpen }) => (isOpen ? "0deg" : "180deg")};
  width: 28px;
`;

const CollapsibleText = styled.p`
  margin: 0;
  padding: 10px 0;
  width: 95%;
  line-height: 30px;
`;

const Collapsible = ({
  title,
  content,
}: {
  title: string;
  content: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);

  const toggleCollapsible = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Container>
      <CollapsibleHeader onClick={toggleCollapsible}>
        <span style={{width: '95%'}}>{title}</span>
        <CollapsibleIcon src={IconOpenClose} isOpen={isOpen} />
      </CollapsibleHeader>
      <CollapsibleContent isOpen={isOpen} ref={contentRef}>
        <CollapsibleText>{content}</CollapsibleText>
      </CollapsibleContent>
    </Container>
  );
};

export default Collapsible;
