import React from "react";
import styled from "styled-components";
import Container from "../../Components/Container";
import Row from "../../Components/Row";
import TeaIcon from "../../assets/images/tea.png";
import Lock from "../../assets/images/lock.png";
import Rocket from "../../assets/images/rocket.png";
import PhoneIcon from "../../assets/images/phone-icon.png";
import PhoneImage from "../../assets/images/phone.png";

const Title = styled.h1`
  font-weight: 700;
  font-size: 40px;
`;

const TitleCard = styled.span`
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
`;

const SubtitleCard = styled.span`
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
`;

const Card = styled.div`
  color: #1e1e1e;
  padding: 35px;
  text-align: left;
`;

const ContainerDiv = styled.div`
  border-left: 2px solid #ffffff;
  min-height: 100px;
  position: relative;
  height: 570px;
`;

const CircleDiv = styled.div`
  width: 22px;
  height: 22px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CircleIntoDiv = styled.div`
  width: 12px;
  height: 12px;
  background-color: #4162fd;
  border-radius: 50%;
`;

const BodyTexts = styled.div`
  position: absolute;
  text-align: left;
  color: #ffffff;
`;

const cardData = [
  {
    title: "Acabe com a dor de cabeça da burocracia",
    subtitle:
      "Esqueça a montanha de papéis e contratos complexos. Com o Room, você gera contratos simples e claros em minutos, sem complicações.",
    icon: TeaIcon,
    iconWidth: "57px",
  },
  {
    title: "Segurança e confiança nas assinaturas",
    subtitle:
      "Preocupado com a validade dos contratos? A assinatura digital do Room garante que tudo seja oficial e seguro, protegendo seus interesses sem sair de casa.",
    icon: Lock,
    iconWidth: "37px",
  },
  {
    title: "Diga adeus aos atrasos de pagamento",
    subtitle:
      "Com cobranças automáticas via PIX, você elimina os stress dos atrasos. Receba pagamentos em dia e tenha um fluxo de caixa constante, sem esforço adicional.",
    icon: Rocket,
    iconWidth: "57px",
  },
  {
    title: "Tenha controle total, a qualquer hora",
    subtitle:
      "Fique no controle da sua locação, onde quer que esteja. Acompanhe contratos e pagamentos em tempo real pelo app, garantindo que você sempre tenha informações atualizadas.",
    icon: PhoneIcon,
    iconWidth: "44px",
  },
];

const Why = () => {
  return (
    <Container style={{ padding: "20px 0px" }}>
      <Container style={{ paddingTop: "0px", paddingBottom: '0px' }}>
        <Title>
          Por que o <span style={{ color: "#4162FD" }}>Room</span> é perfeito
          para você?
        </Title>
      </Container>
      <Row
        xs="1fr"
        sm="1fr"
        md="1fr 1fr"
        lg="1fr 1fr 1fr 1fr"
        xl="1fr 1fr 1fr 1fr"
        style={{ marginTop: "40px" }}
        gap="0px"
      >
        {cardData.map((card, index) => (
          <Card
            key={index}
            style={{ backgroundColor: index % 2 ? "#E8F8FF" : "#CCF3FF" }}
          >
            <Row
              xs="1fr"
              sm="1fr"
              md="1fr"
              lg="1fr"
              xl="1fr"
              style={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <div
                style={{
                  height: "60px",
                  display: "flex",
                  alignItems: "flex-end",
                  marginBottom: '20px'
                }}
              >
                <img src={card.icon} width={card.iconWidth} alt={card.title} />
              </div>
              <div>
                <TitleCard>{card.title}</TitleCard>
                <br />
                <SubtitleCard>{card.subtitle}</SubtitleCard>
              </div>
            </Row>
          </Card>
        ))}
      </Row>
    </Container>
  );
};

export default Why;
