import React, { useState } from "react";
import styled from "styled-components";
import Row from "./Row";
import { Link } from "react-router-dom";
import IconMenu from '../assets/images/icon-menu.svg';

const MenuContainer = styled.nav`
  padding: 20px;
  margin: auto;
  position: relative;

  @media (max-width: 576px) {
    padding: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const MenuItem = styled.a`
  color: #000000;
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
`;

const MenuIcon = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: 576px) {
    display: flex;
    font-size: 24px;
    font-weight: bold;
  }
`;

const CollapsibleMenu = styled.div<{isOpen: boolean}>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  transition: max-height 0.3s ease-out;
  overflow: hidden;
  max-height: 100%;

  @media (max-width: 576px) {
    max-height: ${({ isOpen }) => (isOpen ? "300px" : "0")};
    padding: ${({ isOpen }) => (isOpen ? "20px" : "0")};
    background-color: #fff;
  }
`;

const Menu: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <MenuContainer>
      <MenuIcon onClick={toggleMenu}>
        <img src={IconMenu} width={'40px'} />
      </MenuIcon>
      <CollapsibleMenu isOpen={isOpen}>
        <Row
          xs="auto"
          sm="auto"
          md="auto auto"
          lg="auto auto auto auto"
          xl="auto auto auto auto"
          gap="33px"
          style={{ minHeight: "60px", alignItems: "center" }}
        >
          <Link style={{ textDecoration: "none" }} to={"/#section-1"}>
            <MenuItem>Home</MenuItem>
          </Link>
          <Link style={{ textDecoration: "none" }} to={"/#section-2"}>
            <MenuItem>Como funciona</MenuItem>
          </Link>
          <Link style={{ textDecoration: "none" }} to={"/#section-3"}>
            <MenuItem>Quanto custa</MenuItem>
          </Link>
          <Link style={{ textDecoration: "none" }} to={"/#section-4"}>
            <MenuItem>Fale conosco</MenuItem>
          </Link>
        </Row>
      </CollapsibleMenu>
    </MenuContainer>
  );
};

export default Menu;
