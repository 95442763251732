import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import Header from "./Sections/Header";
import Home from "./Sections/Home";
import About from "./Sections/About";
import Why from "./Sections/Why";
import Price from "./Sections/Price";
import Answers from "./Sections/Answers";
import Footer from "./Sections/Footer";
import { Routes, Route, useLocation } from "react-router-dom";
import Redirect from "./Shared/Redirect";
import TermsOfUse from "./Shared/TermOfUse";
import PrivacyPolicy from "./Shared/PrivacyPolicy";


function App() {
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <>
            <Header />
            <Home />
            <About />
            <Why />
            <Price />
            <Answers />
            <Footer />
          </>
        }
      />
      <Route
        path="/redirect"
        element={
          <>
            <Redirect />
          </>
        }
      />
      <Route
        path="/term-of-use"
        element={
          <>
            <Header />
            <TermsOfUse />
          </>
        }
      />
      <Route
        path="/privacy-policy"
        element={
          <>
            <Header />
            <PrivacyPolicy />
          </>
        }
      />
    </Routes>
  );
}

export default App;
