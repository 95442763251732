import React, { useState } from "react";
import Container from "../../Components/Container";
import Row from "../../Components/Row";
import Logo from "../../assets/images/logo.svg";
import Menu from "../../Components/Menu";
import Button from "../../Components/Button";
import QRCode from '../../assets/images/qr_code.svg'

const Header = () => {
  const [showQRCode, setShowQRCode] = useState(false);

  return (
    <Container>
      <Row
        xs="100%"
        sm="120px auto 144px"
        md="140px auto 144px"
        lg="160px auto 144px"
        xl="180px auto 144px"
        style={{ marginTop: "60px", alignItems: "center" }}
      >
        <img
          src={Logo}
          alt="logo"
          width={"100%"}
          height={"60px"}
          style={{ marginTop: "-30px" }}
        />
        <Menu />
        <div style={{ position: "relative", display: "flex", justifyContent: 'center' }}>
          <Button onClick={() => {setShowQRCode(!showQRCode)}}>Começar agora</Button>
          {showQRCode && (
            <img src={QRCode} width={'144px'} style={{position: 'absolute', top: '58px', margin: 'auto'}} />
          )}
        </div>
      </Row>
    </Container>
  );
};

export default Header;
