import React from "react";
import styled from "styled-components";
import Container from "../Components/Container";
import Row from "../Components/Row";
import LogoBlue from '../assets/images/logo-blue.svg';

const Title = styled.p`
  font-size: 40px;
  font-weight: 800;
  margin-bottom: 60px;
  text-align: center;
`;

const Date = styled.p`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 60px;
`;

const Subtitle = styled.h2`
  font-size: 18px;
  font-weight: 700;
  margin: 0px;
`;

const Section = styled.div`
  p {
    font-weight: 200;
    font-size: 16px;
  }
`;

const List = styled.ul`
  list-style-type: disc;
  margin-left: 0px;
`;

const ListItem = styled.li`
  margin-bottom: 5px;
`;

const BoldText = styled.span`
  font-weight: 700;
`;

const PrivacyPolicy = () => {
  return (
    <Container style={{ lineHeight: "35px" }}>
      <Title>Política de Privacidade</Title>
      <Date>Última atualização: <span style={{ fontWeight: 200 }}>07/10/2024</span></Date>

      <Section>
        <p>
          A presente Política de Privacidade descreve como o Room coleta, usa, armazena e protege as informações pessoais fornecidas pelos usuários ao utilizar o nosso aplicativo e serviços. Ao utilizar o Room, você concorda com os termos descritos nesta política.
        </p>
      </Section>

      <Section>
        <Subtitle>1. Informações que Coletamos</Subtitle>
        <List>
          <ListItem>
            Dados de Cadastro: Nome, e-mail, CPF/CNPJ, endereço, telefone e outros dados fornecidos durante o processo de criação de conta.
          </ListItem>
          <ListItem>
            Informações de Imóveis: Detalhes do imóvel como endereço, valor do aluguel, garantia e outras informações necessárias para a geração de contratos.
          </ListItem>
          <ListItem>
            Informações Financeiras: Dados bancários e de transações para o processamento de pagamentos via PIX, além de históricos de pagamentos.
          </ListItem>
          <ListItem>
            Informações de Uso: Dados sobre como você utiliza o aplicativo, como tempo de uso, interações com funcionalidades e preferências.
          </ListItem>
        </List>
      </Section>

      <Section>
        <Subtitle>2. Como Usamos Suas Informações</Subtitle>
        <List>
          <ListItem>
            Criação de Contratos: Gerar contratos de aluguel personalizados com as informações do imóvel e partes envolvidas.
          </ListItem>
          <ListItem>
            Processamento de Pagamentos: Facilitar e automatizar as cobranças de aluguel via PIX, incluindo o cálculo e desconto das taxas associadas.
          </ListItem>
          <ListItem>
            Comunicações: Enviar notificações relacionadas aos serviços, como alertas de pagamento, atualizações de contratos e novidades sobre o Room.
          </ListItem>
          <ListItem>
            Melhoria do Serviço: Monitorar e analisar o comportamento dos usuários para melhorar a experiência e adicionar novas funcionalidades.
          </ListItem>
          <ListItem>
            Suporte ao Usuário: Responder a dúvidas, fornecer assistência técnica e resolver problemas com o uso do aplicativo.
          </ListItem>
        </List>
      </Section>

      <Section>
        <Subtitle>3. Compartilhamento de Informações</Subtitle>
        <List>
          <ListItem>
            O Room não compartilha, vende ou aluga suas informações pessoais a terceiros, exceto nos seguintes casos:
          </ListItem>
          <ListItem>
            Processamento de Pagamentos: Compartilhamos informações financeiras com parceiros de pagamento, como instituições financeiras, para facilitar transações via PIX.
          </ListItem>
          <ListItem>
            Cumprimento Legal: Podemos divulgar informações pessoais quando exigido por lei, em resposta a ordens judiciais, ou para proteger nossos direitos e segurança.
          </ListItem>
          <ListItem>
            Prestadores de Serviço: Algumas informações podem ser compartilhadas com provedores de serviço que ajudam na operação do Room, como provedores de armazenamento de dados e segurança.
          </ListItem>
        </List>
      </Section>

      <Section>
        <Subtitle>4. Armazenamento e Segurança das Informações</Subtitle>
        <List>
          <ListItem>
            Adotamos medidas técnicas e organizacionais adequadas para proteger suas informações contra perda, uso indevido, acesso não autorizado, divulgação, alteração ou destruição. Isso inclui a criptografia de dados sensíveis e o uso de servidores seguros.
          </ListItem>
          <ListItem>
            Armazenamento: Suas informações são armazenadas em servidores localizados no Brasil ou no exterior, em conformidade com a legislação aplicável.
          </ListItem>
          <ListItem>
            Retenção de Dados: Mantemos suas informações enquanto sua conta estiver ativa ou conforme necessário para cumprir nossas obrigações legais.
          </ListItem>
        </List>
      </Section>

      <Section>
        <Subtitle>5. Direitos dos Usuários</Subtitle>
        <List>
          <ListItem>
            Você tem o direito de:
            <List>
              <ListItem><BoldText>Acessar:</BoldText> Solicitar uma cópia das suas informações pessoais que armazenamos.</ListItem>
              <ListItem><BoldText>Corrigir:</BoldText> Atualizar ou corrigir informações incorretas ou incompletas.</ListItem>
              <ListItem><BoldText>Excluir:</BoldText> Solicitar a exclusão de suas informações pessoais, desde que não seja necessário manter certos dados por motivos legais.</ListItem>
              <ListItem><BoldText>Restringir o Tratamento:</BoldText> Limitar o uso de suas informações pessoais em determinadas circunstâncias.</ListItem>
            </List>
          </ListItem>
        </List>
      </Section>

      <Section>
        <Subtitle>6. Cookies e Tecnologias de Rastreamento</Subtitle>
        <p>
          O Room pode utilizar cookies e outras tecnologias de rastreamento para coletar informações sobre sua navegação e melhorar a experiência do usuário. Esses dados são usados para:
        </p>
        <List>
          <ListItem>Lembrar suas preferências de navegação.</ListItem>
          <ListItem>Analyser o uso do aplicativo para otimização de funcionalidades.</ListItem>
          <ListItem>
            Você pode configurar seu navegador para recusar cookies, mas isso pode afetar o funcionamento do aplicativo.
          </ListItem>
        </List>
      </Section>

      <Section>
        <Subtitle>7. Alterações nesta Política de Privacidade</Subtitle>
        <p>
          O Room se reserva o direito de modificar esta Política de Privacidade a qualquer momento. Quando ocorrerem mudanças significativas, notificaremos você por e-mail ou dentro do aplicativo. O uso continuado dos serviços após a atualização implica na aceitação das novas condições.
        </p>
      </Section>

      <Section>
        <Subtitle>8. Contato</Subtitle>
        <p>
          Se você tiver dúvidas sobre esta Política de Privacidade ou sobre como tratamos seus dados, entre em contato conosco através do e-mail: contato@meuroom.com.br.
        </p>
      </Section>
      <Subtitle style={{fontSize: '16px'}}>contato@meuroom.com.br.</Subtitle>
      <Row
          xs="1fr 1fr"
          sm="1fr 1fr"
          md="1fr 1fr"
          lg="1fr 1fr"
          xl="1fr 1fr"
          style={{ marginTop: "100px", marginBottom: '30px', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <img src={LogoBlue} width={'53px'} />
          <span style={{ fontWeight: 200, textAlign: 'right' }}>© 2024 Room</span>
        </Row>
    </Container>
  );
};

export default PrivacyPolicy;
