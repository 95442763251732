import React from "react";
import styled from "styled-components";
import Container from "../../Components/Container";
import Row from "../../Components/Row";
import DolarIcon from "../../assets/images/dolar.svg";
import PaperIcon from "../../assets/images/paper.svg";
import SignIcon from "../../assets/images/sign.svg";
import CoinIcon from "../../assets/images/coin.svg";
import PixImage from "../../assets/images/sacar.png";
import PaymentsImage from "../../assets/images/recebimentos.png";
import LogoImage from "../../assets/images/logo-white.svg";

const Title = styled.h1`
  font-weight: 700;
  font-size: 40px;
  color: #ffffff;
`;

const TitleCard = styled.span`
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
`;

const SubtitleCard = styled.span`
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
`;

const Card = styled.div`
  background-color: #e8f8ff;
  color: #1e1e1e;
  border-radius: 26px;
  padding: 35px;
  text-align: left;
`;

const ContainerDiv = styled.div`
  border-left: 2px solid #ffffff;
  min-height: 100px;
  position: relative;
  height: 570px;
`;

const CircleDiv = styled.div`
  width: 22px;
  height: 22px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CircleIntoDiv = styled.div`
  width: 12px;
  height: 12px;
  background-color: #4162fd;
  border-radius: 50%;
`;

const BodyTexts = styled.div`
  position: absolute;
  text-align: left;
  color: #ffffff;
`;

const cardData = [
  {
    title: "Cobranças automatizadas",
    subtitle: "Receba os aluguéis via PIX, sem complicações.",
    icon: DolarIcon,
    iconWidth: "40px",
  },
  {
    title: "Contratos rápidos",
    subtitle: "Crie contratos personalizados em minutos para cada imóvel.",
    icon: PaperIcon,
    iconWidth: "30px",
  },
  {
    title: "Assinatura digital",
    subtitle: "Validade jurídica garantida, 100% digital e segura.",
    icon: SignIcon,
    iconWidth: "80px",
  },
  {
    title: "Gestão de pagamentos",
    subtitle: "Tenha controle total dos pagamentos, em qualquer lugar.",
    icon: CoinIcon,
    iconWidth: "40px",
  },
];

const Price = () => {
  return (
    <Container
      style={{
        textAlign: "center",
        backgroundColor: "#4162FD",
        marginTop: "40px",
      }}
      id="section-3"
    >
      <Title>Transparência e simplicidade nos nosso preços</Title>
      <TitleCard>
        Você só paga quando recebe, sem taxas mensais ou surpresas.
      </TitleCard>
      <Row
        xs="1fr"
        sm="1fr"
        md="1fr"
        lg="60% auto"
        xl="60% auto"
        style={{ marginTop: "60px" }}
      >
        <div>
          <InfoSection />
        </div>
        <div style={{ position: "relative" }}>
          <img src={PixImage} width={"80%"} style={{ position: "relative", right: "8%" }} />
          <img
            style={{ position: "relative", top: "-23px", right: "-8%" }}
            src={PaymentsImage}
            width={"75%"}
          />
        </div>
      </Row>
    </Container>
  );
};

const InfoSection = () => {
  const Container = styled.div`
    background-color: #4162fd;
    color: #ffffff;
    font-family: "Plus Jakarta Sans", sans-serif;
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: left;
  `;

  const Card = styled.div`
    display: flex;
    flex-direction: row;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    align-items: center;
  `;

  const Title = styled.h2`
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    margin-top: 0px;
    margin-bottom: 10px;
  `;

  const Text = styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    margin: 0;
  `;

  const Icon = styled.div`
    width: 30px;
    height: 30px;
    border: 2px solid #ffffff;
    border-radius: 6px;
  `;

  return (
    <Container>
      <Card>
        <img src={LogoImage} width={"34px"} style={{ marginRight: "20px" }} />
        <div>
          <Title>Sem taxas fixas</Title>
          <Text>
            Diga adeus a mensalidades! Você só é cobrado quando o pagamento é
            realizado.
          </Text>
        </div>
      </Card>

      <Card>
        <img src={LogoImage} width={"34px"} style={{ marginRight: "20px" }} />
        <div>
          <Title>Custo previsível</Title>
          <Text>
            Planeje seus ganhos com clareza. Se não receber, não paga!
          </Text>
        </div>
      </Card>

      <Card style={{borderBottom: 'none'}}>
        <img src={LogoImage} width={"34px"} style={{ marginRight: "20px" }} />
        <div>
          <Title>Suporte contínuo</Title>
          <Text>
            Conte com nossa equipe para ajudar você a otimizar sua gestão e
            maximizar seus recebimentos.
          </Text>
        </div>
      </Card>
      
      <div style={{ padding: "30px", backgroundColor: "#ffffff", borderRadius: '24px', marginTop: '50px', marginBottom: '50px' }}>
        <span style={{fontWeight: 600, color: '#1E1E1E', fontSize: '16px'}}>
          No Room, acreditamos que a cobrança deve ser justa e transparente. Por
          isso, nossa única taxa é de 5% sobre cada pagamento de aluguel
          recebido através do nosso aplicativo. Isso significa que você só paga
          quando o aluguel entra na sua conta, tornando o gerenciamento mais
          acessível e sem riscos.
        </span>
      </div>
    </Container>
  );
};

export default Price;
