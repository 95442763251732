import React from "react";
import styled from "styled-components";
import Container from "../../Components/Container";
import Row from "../../Components/Row";
import DolarIcon from "../../assets/images/dolar.svg";
import PaperIcon from "../../assets/images/paper.svg";
import SignIcon from "../../assets/images/sign.svg";
import CoinIcon from "../../assets/images/coin.svg";
import PixImage from "../../assets/images/sacar.png";
import PaymentsImage from "../../assets/images/recebimentos.png";
import LogoImage from "../../assets/images/logo-white.svg";
import Collapsible from "../../Components/Collapsible";

const Title = styled.h1`
  font-weight: 700;
  font-size: 40px;
  color: #1e1e1e;
`;

const Border = styled.div`
  height: 1px;
  width: 100%;
  background-color: #1e1e1e;
`;

const cardData = [
  {
    title: "O que é o Room e como ele funciona?",
    subtitle:
      "O Room é um aplicativo que facilita a gestão de aluguéis de imóveis. Ele permite que você crie contratos de aluguel, faça assinaturas digitais com validade jurídica e gerencie as cobranças de aluguel de forma automática via PIX. Tudo em um só lugar e com simplicidade.",
  },
  {
    title: "Quanto custa usar o Room?",
    subtitle:
      "No Room, você só paga quando recebe o aluguel. Cobramos uma taxa de 5% sobre cada transação de aluguel recebida através do nosso app, sem mensalidades ou taxas fixas. Se você não receber, não paga.",
  },
  {
    title: "Os contratos gerados pelo Room têm validade jurídica?",
    subtitle:
      "Sim! Todos os contratos gerados e assinados digitalmente pelo Room têm validade jurídica no Brasil, garantindo segurança tanto para o proprietário quanto para o inquilino.",
  },
  {
    title:
      "Posso usar o Room apenas para criar e assinar contratos, sem utilizar a funcionalidade de cobrança?",
    subtitle:
      "O Room permite que você crie e assine contratos de forma digital, mas o contrato só ficará disponível após o primeiro pagamento do inquilino ser processado pelo app. Nesse momento, é descontada a taxa de 5% e, então, o contrato será liberado com validade jurídica. Essa abordagem garante a sustentabilidade do serviço e facilita a gestão integrada de aluguéis.",
  },
  {
    title: "Como funciona o recebimento via PIX no Room?",
    subtitle:
      "O Room automatiza as cobranças dos aluguéis e permite que você receba diretamente via PIX. O inquilino faz o pagamento e, assim que o valor é transferido, você recebe uma notificação, e o saldo é liberado após o desconto da taxa de 5%.",
  },
];

const Answers = () => {
  return (
    <Container
      style={{
        marginTop: "40px",
        textAlign: "center",
      }}
      id="section-4"
    >
      <Title>
        <span style={{ color: "#4162fd" }}>Perguntas</span> Frequentes
      </Title>
      <Row
        xs="1fr"
        sm="1fr"
        md="1fr"
        lg="1fr"
        xl="1fr"
        style={{ marginTop: "60px" }}
      >
        <div>
          {cardData.map((cardItem, index) => (
            <div key={index}>
              <Collapsible title={cardItem.title} content={cardItem.subtitle} />
              {index + 1 < cardData.length && <Border />}
            </div>
          ))}
        </div>
      </Row>
    </Container>
  );
};

export default Answers;
