import React, { useEffect } from "react";
import LogoBlue from "../assets/images/logo-blue.svg";

const Redirect = () => {
  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    let appStoreUrl = "";

    if (/android/.test(userAgent)) {
      appStoreUrl = 'https://play.google.com/store';
    } else if (/iphone|ipad|ipod/.test(userAgent)) {
      appStoreUrl = 'https://apps.apple.com/app';
    } else {
      appStoreUrl = 'https://example.com';
    }

    setTimeout(() => {
      window.location.href = appStoreUrl;
    }, 2000);
  }, []);

  return (
    <div
      style={{
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        width: "100%",
        paddingTop: 100,
        margin: 0,
        color: "#1E1E1E",
      }}
    >
      <img src={LogoBlue} width={"150px"} height={'150px'} style={{margin: '0px auto'}} />
      <h1>Redirecionando você para a loja de aplicativos...</h1>
      <p>Aguarde um momento.</p>
    </div>
  );
};

export default Redirect;
